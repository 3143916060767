#root{
  height: 100%;
  width: 100%;
  overflow: scroll;
}
body{
  font-family: Arial,helvetica neue,Helvetica,sans-serif;
  background-color: #fff !important;
}
body,p,h1,h2,h3,h4,h5,ul,li{
  margin: 0;
  padding: 0;
}
.am-list-view-scrollview-content{
  width: 100%;
  min-width: initial !important;
}
a{
  text-decoration: none;
}
ul,li{
  list-style: none;
}

